import axios from 'axios'
import { useAuth } from "./authStore"

const localCfg = {
    baseURL: 'https://choolan.net/api/',
}

const devCfg = {
    baseURL: 'https://choolan.net/api/',
}



export const getCfgByOrigin = () => {
    switch (window.location.origin) {
        case "http://localhost:3001": // used in tests
            return localCfg
        case "https://choolan.net":
            return devCfg
        default:
            throw Error("no APIClient config for origin")
    }
}

class APIClient {

    constructor(cfg) {
        const axiosCfg = {
            baseURL: cfg.baseURL,
            timeout: cfg.timeout || 60000,
        }
        this.axios_ = axios.create(axiosCfg)
    }

    setToken(token) {
        try {
            this.token = token
        
            return this
        } catch(e) {
            console.error(e)
        }
    }

    async loadProxies(page=0, limit=10, country, ip=null, state=null, zip=null, city=null, isp=null, conn=null) {
        try {
            const params = {
                cc: country,
                cursor: page*10,
                limit: limit,
                segments: true,
            }
            // console.log('CONN : '+conn)
            // console.log('state : '+state)
            if(ip!==null){
                // Object.defineProperty(params, "ip", {value: ip})
                params['ip1'] = ip
            }

            if(state!==null){
                params['reg'] = state
            }
            if(zip!==null)params['zip'] = zip
            if(city!==null)params['city'] = city
            if(isp!==null)params['isp'] = isp
            if(conn!=='')params['conn'] = conn
            
            const proxiesList = await this.__call('/agents', 'GET', params)
            // console.log(params)

            return proxiesList
        } catch (e) {
            console.error(e)
        }
    }

    async getUserData(userId) {
        try {
            const userData = await this.__call('/accounts/my', 'GET')
  
            return userData
        } catch (e) {
            // console.error('ERROR')
            console.error(e)
        }
    }

    async buyProxy(proxyData) {
        try {
            const response = await this.__call('/leases', 'POST', {}, proxyData)
            
            return response
        } catch (e) {
            // console.error('ERROR')
            console.error(e)

            throw e
        }
    }

    async getUserHistory(startDate, endDate, page, rowsPerPage) {
        try {
            const params = {
                offset: page*10,
                limit: rowsPerPage,
                start: startDate,
                end: endDate,
            }
            const historyData = await this.__call('/leaseHistory', 'GET', params)
            
            return historyData
        } catch (e) {
            // console.error(e)

            throw e
        }
    }

    async saveProxyWhitelist(id, whitelist) {
        try {
            const body = {
                whitelist: whitelist
            }
            const response = await this.__call('/authport/create/'+id, 'POST', {}, body)
            // console.log(response)
            
            return response
        } catch (e) {
            // console.error(e)

            throw e
        }
    }

    async renewProxy(id) {
        try {
            
            const response = await this.__call('/leases/'+id, 'PATCH')
            console.log(response)
            
            return response
        } catch (e) {
            // console.error(e)

            throw e
        }
    }

    async getUserPayments(startDate, endDate, page, rowsPerPage) {
        try {
            const params = {
                offset: page*10,
                limit: rowsPerPage,
                start: startDate,
                end: endDate,
            }

            const paymentsData = await this.__call('/txrecords/', 'GET', params)
            
            return paymentsData
        } catch (e) {
            console.error(e)
        }
    }

    async createRefundTicket(id, reason) {
        try {
            const body = {
                leaseID: id,
                reason: reason
            }
            // console.log(body)
            const response = await this.__call('/tickets/', 'POST', {}, body)
            
            return response
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    async getUserTickets(page, rowsPerPage) {
        try {
            const params = {
                offset: page*10,
                limit: rowsPerPage,
            }

            const ticketsData = await this.__call('/tickets/', 'GET', params)
            
            return ticketsData
        } catch (e) {
            console.error(e)
        }
    }

    async checkProxySpeed(id) {
        try {
            const resultData = await this.__call('/agents/'+id+'/speedcheck/', 'GET',)
            
            // const resultData = {
            //     "speed": 25553285,
            //     "checkTime": "2024-08-21T19:09:15.225Z"
            // }

            return resultData
        } catch (e) {
            console.error(e)
        }
    }

    async getCountries() {
        try {
            const params = {
                all: true,
            }

            const countryData = await this.__call('/totals/agents', 'GET', params)
            
            return countryData
        } catch (e) {
            console.error(e)
        }
    }

    async loacActiveProxies() {
        try {
            const activeList = await this.__call('/leases', 'GET')
            
            return activeList
        } catch (e) {
            console.error(e)
        }
    }

    async changeUserPassword(oldPasswod, newPassword) {
        try {
            const body = {
                old: oldPasswod,
                new: newPassword
            }

            // const result = await this.__call('https://choolan.net/api'+'/accounts', 'PATCH', {}, body)
            const result = true

            return result
        } catch (e) {
            console.error(e)
        }
    }

    async getUserInfo(id) {
        try {
            const result = await this.__call('/accounts/'+id, 'GET', {})

            return result
        } catch (e) {
           throw e
        }
    }

    async getLeaseInfo(id) {
        try {
            const result = await this.__call('/leases/'+id, 'GET', {})

            return result
        } catch (e) {
            throw e
        }
    }

    async refundLeaseAdmin(id) {
        try {
            const result = await this.__call('/leases/'+id, 'DELETE', {})

            return result
        } catch (e) {
            throw e
        }
    }

    async addUserBalance(userId, balanceC) {
        try {
            const result = await this.__call('/accounts/'+userId, 'PATCH', {}, {balanceDeltaC: parseInt(balanceC)})

            return result
        } catch (e) {
            throw e
        }
    }

    async changeUserPasswordAdmin(userId, password) {
        try {
            const result = await this.__call('/accounts/'+userId, 'PATCH', {}, {password: password})

            return result
        } catch (e) {
            throw e
        }
    }

    async geticketsAdmin(page, rowsPerPage, status) {
        try {
            const params = {
                offset: page,
                limit: rowsPerPage,
                status: status,
            }

            const ticketsData = await this.__call('/tickets/', 'GET', params)
            
            return ticketsData
        } catch (e) {
            console.error(e)
        }
    }

    async closeTicketdAdmin(ticketId, description, status) {
        try {
            const result = await this.__call('/tickets/'+ticketId, 'PATCH', {}, {description: description, status: status})

            return result
        } catch (e) {
            throw e
        }
    }

    async __call(uri, method, params=null, body=null) {
        method = method || 'GET'

        try {
            const headers = {
                'authorization': 'Bearer ' + this.token,
            }
            // console.log(params)
            const resp = await this.axios_.request(uri, {
                method,
                headers,
                params,
                data: body,
            })

            return resp.data
        } catch (err) {
            // console.log('API ERROR')
            // console.log(err.response)
            return this.handleError_(err)
        }
    }

    authNeed() {
        localStorage.removeItem('token', null)
        window.location.href = '/login'
    }

    handleError_(err) {
        console.log(err)
        if (err.response) {
            switch (err.response.status) {
                case 401:
                   return this.authNeed()
                case 502:
                    return Promise.reject(new Error('got a 502'))
                default:
                    return Promise.reject(err)
            }
        } else if (err.request) {
            return Promise.reject(err)
        }
    }
}

export default APIClient