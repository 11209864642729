import * as React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

const useStyles = makeStyles((theme) => ({
    box: {
        width: '512px',
        height: '608px',
        border: '1px',
        display: 'flex',
        alignItems: 'center',
        top: '240px',
        left: '740px',
        border: '1px solid grey',
        gap: '48px',
        paddingLeft: '96px',
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centered: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#8000FF',
      },
      boxInline: {
        width : '100%',
        alignItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '96px',
        paddingRight: '96px',
      },
      signIn: {
        width: '320px',
        height: '40px',
      },
      emailDiv:{
        paddingBottom: '30px',
      },
      emailInput: {
        width: '320px',
        height: '56px',
      },
      passwdInput: {
        width: '320px',
        height: '56px',
      },
      button: {
        width: '320px',
        height: '56px',
      },
      link: {
        color: '#1A53FF',
      },
      divSignInLink : {
        color: '#6E7680',
      },
      errorDiv : {
        color: '#E0004B',
        paddingBottom: '10px',
        fontSize: 12
      },
      elemetsDiv1 : {
        paddingBottom: 20,
        color: '#6E7680',
        fontSize: 12,
      },
      elemetsDiv : {
        color: '#6E7680',
        fontSize: 12,
        paddingBottom: 20,
      }
}))

const ForgotPassword = (props) => {
    const classes = useStyles()
    const [showPassword, setShowPassword] = React.useState(false)
    const [isError, showError] = React.useState(false)

    return (
        <div className={classes.centered}>
            <Box my={4} p={2} className={classes.box}>
                <div id="boxInline" className={classes.boxInline}>
                    <div className={classes.signIn}>Password Recovery</div>
                    <div id="elements" className={classes.elemetsDiv1}>
                        Please contact support by email : or telegram 
                    </div>
                </div>
            </Box>
        </div>
       
    )
}

export default ForgotPassword